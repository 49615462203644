export enum FeatureFlag {
  CUSTOM_BACKGROUND = 'custombackground',
  BOOMCARDS_SITESHARE = 'boomcardsSiteShare',
  BOOMCARDS_IFRAME = 'boomcardsIframe',
  INTEGRATED_LIBRARY = 'integratedLibrary',
  NEW_LIBRARY_ACTIVITIES = 'newLibraryActivities',
  NEW_LIBRARY_MY_MATERIALS = 'newLibraryMyMaterials',
  INACTIVITY_AUTOLOGOUT_BYPASS = 'inactivityAutologoutBypass',
  VIEW_AI_SUMMARY = 'viewAISummary',
  WJV_ASSESSMENTS = 'wjvAssessments',
  NEW_PARTICIPANTS_PANEL = 'newParticipantsPanel',
  INSTANT_VIDEO_SITE_SHARE = 'instantVideoSiteShare',
}
