import { Component } from '@angular/core';

@Component({
  selector: 'pl-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less'],
})
export class AppComponent {
  onActivate() {
    const loader = document.querySelector('#page-loader');
    if (loader) {
      loader.remove();
    }
  }
}
