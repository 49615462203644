import { createSelector } from '@ngrx/store';
import { AppState } from '@root/src/app/store';

export const selectAuth = (store: AppState) => store.auth;

export const selectUserId = createSelector(selectAuth, state => {
  return state.userId;
});

export const selectCurrentUser = createSelector(selectAuth, state => {
  return state?.user;
});

export const selectCurrentUserEmail = createSelector(
  selectCurrentUser,
  user => user?.email,
);

export const selectCurrentUserInitials = createSelector(
  selectCurrentUser,
  user => `${user?.first_name?.charAt(0)}${user?.last_name?.charAt(0)}`,
);

export const selectCurrentUserFullName = createSelector(
  selectCurrentUser,
  user => `${user?.first_name} ${user?.last_name}`.trim(),
);

export const selectCurrentUserUsername = createSelector(
  selectCurrentUser,
  user => user?.username,
);

export const selectUiFlags = createSelector(selectAuth, state => {
  return state.uiFlags;
});

export const selectIsProvider = createSelector(selectCurrentUser, user => {
  return (
    user &&
    user.groups &&
    (user.groups.indexOf('Provider') > -1 ||
      user.groups.indexOf('Service & Support') > -1 ||
      user.groups.indexOf('School Staff Providers') > -1 ||
      user.groups.indexOf('Private Practice') > -1)
  );
});

export const selectAuthGroups = createSelector(
  selectAuth,
  state => state.groups,
);

export const selectIsSaasProvider = createSelector(
  selectAuthGroups,
  groups => groups.SaaS,
);

export const selectIsLimitedSaasAccess = createSelector(
  selectCurrentUser,
  user => user?.groups?.includes('Limited SaaS Access'),
);

export const selectIsViaUser = createSelector(selectCurrentUser, user =>
  user?.groups?.includes('via'),
);
