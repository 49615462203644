import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { CardDisplayOptionsComponent } from './card-display-options/card-display-options.component';
import { MultiSelectMenuComponent } from './multi-select-menu/multi-select-menu.component';
import { PLCommonOverlaysComponent } from './pl-common-overlays/pl-common-overlays.component';
import { PLDocumentItemComponent } from './pl-document-item/pl-document-item.component';
import { PLHijackDrawerComponent } from './pl-hijack-drawer/pl-hijack-drawer.component';
import { PLClientAppointmentsSelectComponent } from './pl-client-appointment-select/pl-client-appointments-select.component';
import { PLConfirmComponent } from './pl-confirm/pl-confirm.component';
import { PLLoaderComponent } from './pl-loader/pl-loader.component';
import { PLWindowWarningModule } from './window-warning';
import { SortMenuComponent } from './sort-menu/sort-menu.component';
import { PlayerCountSelectComponent } from './player-count-select/player-count-select.component';

import { MatIconModule } from '@angular/material/icon';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';
import { PLAccordionDirective } from './pl-expansion-panel/pl-accordion.directive';
import { PLExpansionPanelComponent } from './pl-expansion-panel/pl-expansion-panel.component';
import { PLPanelHeaderDirective } from './pl-expansion-panel/pl-expansion-panel-header.directive';
import { PLIconComponent } from './pl-icon/pl-icon.component';
import { MatButtonModule } from '@angular/material/button';
import { PLConfirmAlertComponent } from './pl-confirm-alert/pl-confirm-alert.component';
import { AlertMessageModule } from './alert-message';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    MatCheckboxModule,
    MatDialogModule,
    MatIconModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatListModule,
    MatMenuModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatTooltipModule,
    PLWindowWarningModule,
    AlertMessageModule,
  ],
  exports: [
    CardDisplayOptionsComponent,
    MultiSelectMenuComponent,
    PlayerCountSelectComponent,
    PLClientAppointmentsSelectComponent,
    PLCommonOverlaysComponent,
    PLDocumentItemComponent,
    PLHijackDrawerComponent,
    PLLoaderComponent,
    PLAccordionDirective,
    PLExpansionPanelComponent,
    PLPanelHeaderDirective,
    PLIconComponent,
    PLConfirmAlertComponent,
    SortMenuComponent,
  ],
  declarations: [
    CardDisplayOptionsComponent,
    MultiSelectMenuComponent,
    PlayerCountSelectComponent,
    PLClientAppointmentsSelectComponent,
    PLCommonOverlaysComponent,
    PLDocumentItemComponent,
    PLHijackDrawerComponent,
    PLConfirmComponent,
    PLLoaderComponent,
    PLAccordionDirective,
    PLExpansionPanelComponent,
    PLPanelHeaderDirective,
    PLIconComponent,
    PLConfirmAlertComponent,
    SortMenuComponent,
  ],
  providers: [],
})
export class PLCommonComponentsModule {}
